<template>
  <v-container fluid class="mt-2">
    <v-row class="orange">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="2" align-self="center" class="text-center">
            <div class="similar-title text-center white--text">
              مطالب
              <br />
              مشابه
            </div>
            <v-btn large outlined color="white" class="mt-3" to="/articles">
              مشاهده همه
            </v-btn>
          </v-col>
          <v-col cols="12" md="10" v-if="loading">
            <my-swiper
              :data="Array(4)"
              :maxCount="4"
              hasNavigation
              dark
              :spaceBetween="10"
              :loop="false"
              class="amazing-offer-swiper"
            >
              <template #default>
                <v-skeleton-loader type="card, actions" class="rounded-0" />
              </template>
            </my-swiper>
          </v-col>
          <v-col cols="12" md="10" v-else>
            <my-swiper
              :data="articles"
              :sizes="[3, 3, 2, 2, 1]"
              hasNavigation
              fixSpace
              dark
              :spaceBetween="10"
              :loop="false"
              class="amazing-offer-swiper"
            >
              <template #default="{props}">
                <article-item
                  :id="props.item.id"
                  :img="
                    props.item.media
                      ? props.item.media.url
                      : require('../../assets/img/thumb.jpg')
                  "
                  :title="props.item.title"
                  :date="props.item.created_at"
                  :slug="props.item.slug"
                />
              </template>
            </my-swiper>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import MySwiper from "../../components/MySwiper.vue";
import MyAxios from "../../constants/MyAxios";
import ArticleItem from "./ArticleItem.vue";

export default {
  components: {
    MySwiper,
    ArticleItem,
  },
  data: () => ({
    articles: [],
    loading: true,
  }),
  mounted() {
    this.GetData();
  },
  props: {
    slug: {
      type: String,
    },
  },
  methods: {
    GetData: function() {
      if (!this.loading) this.loading = true;
      MyAxios.get("/posts/similar/" + this.slug, {
        params: {
          with: "media",
        },
      })
        .then((response) => {
          this.articles = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
  watch: {
    slug() {
      this.GetData();
    },
  },
};
</script>

<style></style>
