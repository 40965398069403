<template>
  <div class="grey lighten-3" style="position: relative">
    <v-container
      fluid
      class="pa-4 d-flex white--text"
      :class="$vuetify.breakpoint.smAndDown && 'simple-header'"
    >
      <v-row style="z-index: 2">
        <v-col cols="12" md="3">
          <v-img
            :src="img ? img : require('../../assets/img/thumb.jpg')"
            class="sp-img rounded-xl elevation-0 mr-md-auto ml-md-0 mx-auto"
          />
        </v-col>
        <v-col cols="12" md="9" class="text-center text-md-start">
          <h1>{{ title }}</h1>
          <div class="d-flex flex-wrap justify-center justify-md-start">
            <v-chip
              class="ma-2 rounded-lg"
              text-color="white"
              label
              color="info"
            >
              مقالات
            </v-chip>
            <v-chip
              class="ma-2 rounded-lg"
              text-color="white"
              label
              color="orange"
            >
              {{ date | moment("jYYYY/jMM/jDD") }}
            </v-chip>
            <v-rating
              color="yellow"
              dark
              :value="rate"
              large
              readonly
              
            />
          </div>
          <my-routes-list
            :routes="routes"
            class="mt-4 justify-center justify-md-start"
            :dark="$vuetify.breakpoint.mdAndUp"
          />
        </v-col>
      </v-row>
      <!-- <div class="content">
            </div> -->
    </v-container>
    <div class="simple-header-absolute" />
  </div>
</template>

<script>
import MyRoutesList from "../MyRoutesList.vue";
export default {
  components: { MyRoutesList },
  props: {
    routes: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    date: {
      type: Date,
      required: true,
    },
    img: {
      type: String,
      default: null,
    },
    rate: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped>
.simple-header-absolute {
  width: 100%;
  height: 130px;
  position: absolute;
  top: 0;
  z-index: 1;
  background: url("../../assets/header.jpg");
}
.simple-header {
  width: 100%;
  z-index: 1;
  background: url("../../assets/header.jpg");
}
.sp-img {
  /* width: 20em;
    height: 20em; */
  max-width: 300px;
  max-height: 200px;
  border: 5px white solid;
  /* position: absolute; */
  right: 0;
}
</style>
