<template>
  <v-container fluid class="pa-0" v-if="data">
    <!-- <cart-button /> -->
    <article-header
      :title="data.title"
      :routes="[
        { title: 'خانه', url: '/' },
        { title: 'دسته بندی', url: '/categories' },
        { title: 'مقالات', url: '/articles/' },
        { title: data.title },
      ]"
      :date="data.created_at"
      :img="data.media ? data.media.url : null"
      :rate="data.rate"
    />

    <v-container fluid>
      <v-row class="grey lighten-3 pb-4" justify="center">
        <v-col cols="12" md="8" lg="8">
          <v-card class="rounded-xl ma-2 mt-0 ml-0 moholand-shadow">
            <div
              class="text-justify pa-4 black--text fix-html-images"
              v-html="data.desc"
            ></div>
            <v-card-actions class="align-self-end" v-if="data.tags.length">
              <div class="d-flex flex-wrap">
                <v-chip
                  class="ma-2 rounded-lg"
                  text-color="white"
                  label
                  color="orange darken-1"
                  v-for="(tag, i) in data.tags"
                  :key="i"
                >
                  {{ tag.title }}
                </v-chip>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <comments
            :model_id="data.id"
            :model_slug="data.slug"
            comment_type="post"
          />
        </v-col>
      </v-row>
    </v-container>
    <similar-articles v-if="data" :slug="data.slug" />
  </v-container>
</template>

<script>
import ArticleHeader from "../../components/Pages/ArticleHeader.vue";
import Comments from "../../components/Pages/Comments.vue";
import SimilarArticles from "../../components/Pages/SimilarArticles.vue";
import MyAxios from "../../constants/MyAxios";
export default {
  components: {
    Comments,
    ArticleHeader,
    SimilarArticles,
  },
  created() {
    this.GetData();
  },
  data() {
    return {
      slug: this.$route.params.slug || 0,
      data: null,
    };
  },
  methods: {
    GetData: function() {
      this.$root.$emit("globalLoadingSet", true);
      MyAxios.get("/posts/index/" + this.slug, {
        params: {
          noPaginate: true,
          with: ["category", "media", "tags"],
        },
      })
        .then((response) => {
          this.data = response.data[0];
          this.$root.$emit("globalLoadingSet", false);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    "$route.params.slug"(slug) {
      this.slug = slug;
      this.GetData();
    },
  },
};
</script>

<style scoped>
.product-img {
  /* width: 20em;
    height: 20em; */
  border: 5px solid;
  max-height: 150px;
  max-width: 150px;
}
</style>
