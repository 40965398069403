<template>
  <v-card
    class="moholand-shadow mx-auto rounded-lg"
    max-width="300"
    @click="navigate"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-img height="200" :src="img">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            v-if="img"
            indeterminate
            color="grey lighten-2"
          ></v-progress-circular>
          <v-btn v-else text disabled>
            بدون تصویر
          </v-btn>
        </v-row>
      </template>
    </v-img>

    <v-card-text class="text-center px-0">
      <h3 class="custom-title px-1">{{ title }}</h3>
      <v-divider class="ma-4 orange darken-1" v-if="withDate" />
      <h5 v-if="withDate">{{ date | moment("jYYYY/jMM/jDD") }}</h5>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    img: {
      type: Object | String,
    },
    slug: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    date: {
      type: String,
    },
    withDate: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    navigate() {
      if (this.slug) this.$router.push("/articles/" + this.slug);
    },
  },
};
</script>

<style scoped>
.custom-title {
  line-height: 1.2em;
  height: 2.4em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
